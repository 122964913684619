<template>
  <div class="app">
    <div class="Pic">
      <img
        src="../../../assets/allImage//presscenter/toutu_xinwenzhognxin@1x.png"
        alt=""
        width="100%"
        height="100%"
      />
    </div>
    <div>
      <!-- 新闻详情 -->
      <div>
        <div class="app-content">
          <img :src="backImage" class="backPic" @click="backFn" alt="" />
          <span class="BackPage" @click="backFn">返回</span>
          <h4>{{ detailsList[0].title }}</h4>
          <div class="heads">
            <div class="timeData">
              <span>时间：</span>
              <span class="commSyl">{{ detailsList[0].releaseTime }}</span>
            </div>
            <div class="pageView">
              <span> 浏览次数：</span>
              <span class="commSyl"> {{ detailsList[0].viewsNumbers }}</span>
            </div>
            <div>
              <span>信息来源：</span>
              <span class="commSyl">{{ detailsList[0].source }}</span>
            </div>
          </div>
          <div class="listSon">
            <div class="deatils" v-html="detailsList[0].content"></div>
          </div>
        </div>

        <div class="thePrecedingAndSubsequentArticles">
          <!-- <div class="box_left">
            <span v-if="detailsList[1].id !== null">上一篇：</span>
            <span class="son_lt" @click="previousPosts(detailsList[1])">{{
              detailsList[1].title ? detailsList[1].title : ''
            }}</span>
          </div>
          <div class="box_right">
            <span v-if="detailsList[2].id !== null">下一篇：</span>
            <span class="son_Rt" @click="nextChapter(detailsList[2])">
              {{ detailsList[2].title ? detailsList[2].title : '' }}
            </span>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <BottomField></BottomField> -->
  </div>
</template>

<script>
import { companyArticleDetails, companyDeails } from '@/api/index';
import { searchDetails } from '@/api/index';
// import { mapState } from 'vuex';
export default {
  data() {
    return {
      title: '',
      Details: [], // 部门列表
      detailsList: [], // 详情雷彪
      backImage: require('../../../assets/allImage/reback.png'),
      list: [],
      detailArrayList: [],
      myId: '',
      listArr: [],
      currentIndex: '', //当前索引
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newParams, oldParams) {
        this.title = newParams.title;
        this.detailsGetList(newParams); // 新闻详情列表
      },
    },
  },
  methods: {
    async detailsGetList(newParams) {
      this.detailsList = [];
      var form = {};
      form.id = newParams.id;
      form.title = newParams.title;
      const res = await searchDetails(form);
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.detailsList = res.data.data;
        }
      }
    },

    // 回退上一步
    backFn() {
      this.$router.go(-1);
    },
    // 上一篇
    previousPosts(params) {
      if (params.isExternalLink == 1) {
        window.open(params.externalLink, '_blank');
        return;
      } else {
        var previousPosts = {};
        previousPosts.id = params.id;
        previousPosts.title = this.title;
        this.detailsGetList(previousPosts);
      }
    },

    // 下一篇
    nextChapter(params) {
      if (params.isExternalLink == 1) {
        window.open(params.externalLink, '_blank');
        return;
      } else {
        var nextChapter = {};
        nextChapter.id = params.id;
        nextChapter.title = this.title;
        this.detailsGetList(nextChapter);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.commSyl {
  color: #c1c1c1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  // padding: 1.25rem 0;

  .app-content {
    width: 60%;
    // padding: 2.5rem 0.9375rem;
    background-color: #fff;
    border-radius: 0.25rem;
    margin: 0.625rem auto;
    position: relative;

    .backPic {
      width: 1.875rem;
      position: absolute;
      left: 1.25rem;
      top: 1.25rem;
      cursor: pointer;
    }
    .BackPage {
      position: absolute;
      left: 3.4375rem;
      top: 1.5625rem;
      font-size: 1rem;
      color: #0aa4ee;
      cursor: pointer;
    }
    h4 {
      width: 100%;
      height: 2.1875rem;
      line-height: 2.1875rem;
    }
    .heads {
      width: 100%;
      height: 2.8125rem;
      border-bottom: 0.0625rem solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      .timeData {
        margin-right: 0.9375rem;
      }
      .pageView {
        margin-right: 0.9375rem;
      }
    }
    .listSon {
      width: 100%;
      line-height: 2.1875rem;
      padding: 0.9375rem;
      text-align: left;
      .deatils {
        text-indent: 2rem;
      }
    }
  }
  .thePrecedingAndSubsequentArticles {
    width: 60%;
    height: 2.8125rem;
    margin: 0 auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_left {
      font-size: 0.75rem;
      .son_lt {
        color: #c1c1c1;
        cursor: pointer;
      }
    }
    .box_right {
      font-size: 0.75rem;
      .son_Rt {
        color: #c1c1c1;
        cursor: pointer;
      }
    }
  }
}

::v-deep img {
  max-width: 90% !important;
  text-indent: 0;
}
.Pic {
  img {
    max-width: 100% !important;
  }
}
</style>
